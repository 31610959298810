import React from 'react';
import {
  StyledIcon,
  StyledPageNumber,
  StyledPagination,
  StyledText,
  StyledPaginationInfo,
  StyledPaginationControls,
} from './Pagination.styles';

type Props = {
  currentPage: number;
  totalPageCount: number;
  totalRowCount: number;
  totalRowsOnPage: number;
  paginationLimit: number;
  nextPage: () => void;
  previousPage: () => void;
};

export function Pagination({
  currentPage,
  totalPageCount,
  totalRowCount,
  totalRowsOnPage,
  paginationLimit,
  nextPage,
  previousPage,
}: Props) {
  const isLastPage = currentPage === totalPageCount - 1;
  const isFirstPage = currentPage === 0;
  const firstRowPosition = currentPage * paginationLimit;

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      previousPage();
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPageCount - 1) {
      nextPage();
    }
  };

  return (
    <StyledPagination>
      <StyledPaginationInfo>
        Showing {firstRowPosition + 1} - {firstRowPosition + totalRowsOnPage} of {totalRowCount}{' '}
        entries
      </StyledPaginationInfo>
      <StyledPaginationControls>
        <StyledIcon name="chevron-left" onClick={handlePreviousPage} isDisabled={isFirstPage} />
        <StyledText>
          Page{' '}
          <StyledPageNumber>
            <span>{currentPage + 1}</span>
          </StyledPageNumber>
          of {totalPageCount}
        </StyledText>
        <StyledIcon name="chevron-right" onClick={handleNextPage} isDisabled={isLastPage} />
      </StyledPaginationControls>
    </StyledPagination>
  );
}
