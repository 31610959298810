// Base
export const BASE_AUTH = '/auth';
export const BASE_CROWDLABEL = '/teach';
export const BASE_DATASETS = '/datasets';
export const BASE_MOONBOW = '/explain';
export const BASE_SUNBOW = '/review';
export const BASE_CLOUDBURST = '/workflows';
export const BASE_ADMIN = '/admin';
export const BASE_NO_ACCESS = '/no_access';
export const BASE_ZEPHYR = '/gallery';
export const BASE_PROMPT_STUDIO = '/prompt-studio';

// Auth
export const AUTH_CHANGE_PASSWORD = `${BASE_AUTH}/change_password`;
export const AUTH_CONFIRM_ACCOUNT = `${BASE_AUTH}/confirm_account`;
export const AUTH_FORGOT = `${BASE_AUTH}/forgot`;
export const AUTH_REGISTER = `${BASE_AUTH}/register`;
export const AUTH_ACCOUNT = `${BASE_AUTH}/account`;
export const AUTH_LOGOUT = `${BASE_AUTH}/logout`;
export const AUTH_SSO_LOGIN = `${BASE_AUTH}/users/saml/sso`;

// Admin
export const ADMIN_REPORTS = `/reports`;

// Sharknado - "Datasets"
export const DATASETS_DETAILS = `${BASE_DATASETS}/:datasetId/:detail`;
export const DATASET_NEW = `${BASE_DATASETS}/new`;
export const DATASETS_EXAMPLES_LIST = `${BASE_DATASETS}/:datasetId/models/:modelGroupId/examples`;
export const GET_DATASET_TEAM = (datasetId: number) => `${BASE_DATASETS}/${datasetId}/team`;
export const GET_DATASET_FILES = (datasetId: number) => `${BASE_DATASETS}/${datasetId}/files`;
export const GET_DATASET_SETTINGS = (datasetId: number) => `${BASE_DATASETS}/${datasetId}/settings`;
export const GET_DATASET_EXAMPLES = (datasetId: number, modelGroupId: number) =>
  `${BASE_DATASETS}/${datasetId}/models/${modelGroupId}/examples`;

// Gallery
export const GALLERY_DETAILS = `${BASE_ZEPHYR}/:detail`;
export const GALLERY_ADD_NEW = `${BASE_ZEPHYR}/:detail/new`;
export const GET_GALLERY_DETAIL = ({ detail }: { detail: string }) => `${BASE_ZEPHYR}/${detail}`;
export const GET_GALLERY_DETAIL_ADD_NEW = ({ detail }: { detail: string }) =>
  `${BASE_ZEPHYR}/${detail}/new`;

// Crowdlabel - "Teach"
export const CROWDLABEL_TASKS_DETAILS = `${BASE_CROWDLABEL}/:questionnaireId`;
export const CROWDLABEL_LABELING = `${BASE_CROWDLABEL}/labeling/:questionnaireId`;
export const CROWDLABEL_LABELING_SINGLE = `${BASE_CROWDLABEL}/example/:modelId/:exampleId`;
export const CROWDLABEL_SUMMARIZATION_STUDIO = `${BASE_CROWDLABEL}/summarization/:questionnaireId`;
export const CROWDLABEL_EXTRACTION_STUDIO = `${BASE_CROWDLABEL}/extraction-studio/:questionnaireId`;
export const GET_CROWDLABEL_DETAILS = ({ questionnaireId }: { questionnaireId: number }) =>
  `${BASE_CROWDLABEL}/${questionnaireId}`;
export const GET_CROWDLABEL_LABELING = ({ questionnaireId }: { questionnaireId: number }) =>
  `${BASE_CROWDLABEL}/labeling/${questionnaireId}`;
export const GET_CROWDLABEL_LABELING_SINGLE = ({
  modelGroupId,
  exampleId,
}: {
  modelGroupId: number;
  exampleId: number;
}) => `${BASE_CROWDLABEL}/example/${modelGroupId}/${exampleId}`;
export const GET_SUMMARIZATION_STUDIO = ({ questionnaireId }: { questionnaireId: number }) =>
  `${BASE_CROWDLABEL}/summarization/${questionnaireId}`;
export const GET_EXTRACTION_STUDIO = ({ questionnaireId }: { questionnaireId: number }) =>
  `${BASE_CROWDLABEL}/extraction-studio/${questionnaireId}`;

// Prompt Studio
export const GET_PROMPT_STUDIO = ({ modelGroupId }: { modelGroupId: number }) =>
  `${BASE_PROMPT_STUDIO}/${modelGroupId}`;

// Moonbow - "Explain"
export const MOONBOW_DETAILS = `${BASE_MOONBOW}/datasets/:datasetId/models/:modelGroupId/:detail`;
export const GET_MOONBOW_NEW = ({ datasetId }: { datasetId: number }) =>
  `${BASE_MOONBOW}/datasets/${datasetId}/models/new`;
export const GET_MOONBOW_DETAILS = (
  { datasetId, modelGroupId, detail }: { datasetId: number; modelGroupId: number; detail: string } //enum
) => `${BASE_MOONBOW}/datasets/${datasetId}/models/${modelGroupId}/${detail}`;
export const GET_MOONBOW_PERFORMANCE = ({
  datasetId,
  modelGroupId,
}: {
  datasetId: number;
  modelGroupId: number;
}) => `${BASE_MOONBOW}/datasets/${datasetId}/models/${modelGroupId}/performance`;
export const GET_MOONBOW_BALANCE = ({
  datasetId,
  modelGroupId,
}: {
  datasetId: number;
  modelGroupId: number;
}) => `${BASE_MOONBOW}/datasets/${datasetId}/models/${modelGroupId}/balance`;
export const GET_MOONBOW_TRAINING = ({
  datasetId,
  modelGroupId,
}: {
  datasetId: number;
  modelGroupId: number;
}) => `${BASE_MOONBOW}/datasets/${datasetId}/models/${modelGroupId}/training`;

// Sunbow - "Review"
export const REVIEW_QUEUE = `${BASE_SUNBOW}/queues/:workflowId`;
export const EXCEPTIONS_QUEUE = `${BASE_SUNBOW}/exceptions/:workflowId`;

export const SINGLE_REVIEW_QUEUE = `${BASE_SUNBOW}/queues/:workflowId/submission/:submissionId`;
export const SINGLE_EXCEPTIONS_QUEUE = `${BASE_SUNBOW}/exceptions/:workflowId/submission/:submissionId`;
export const SINGLE_SUNBOW_QUEUE_SUCCESS = `${BASE_SUNBOW}/queues/:workflowId/submission/:submissionId/success`;

export const GET_REVIEW_QUEUE = ({ workflowId }: { workflowId: number }) =>
  `${BASE_SUNBOW}/queues/${workflowId}`;
export const GET_EXCEPTIONS_QUEUE = ({ workflowId }: { workflowId: number }) =>
  `${BASE_SUNBOW}/exceptions/${workflowId}`;

export const GET_SINGLE_EXCEPTIONS_QUEUE = ({
  workflowId,
  submissionId,
}: {
  workflowId: number;
  submissionId: number;
}) => `${BASE_SUNBOW}/exceptions/${workflowId}/submission/${submissionId}`;
export const GET_SINGLE_REVIEW_QUEUE = ({
  workflowId,
  submissionId,
}: {
  workflowId: number;
  submissionId: number;
}) => `${BASE_SUNBOW}/queues/${workflowId}/submission/${submissionId}`;
export const GET_SINGLE_SUNBOW_QUEUE_SUCCESS = ({
  workflowId,
  submissionId,
}: {
  workflowId: number;
  submissionId: number;
}) => `${BASE_SUNBOW}/queues/${workflowId}/submission/${submissionId}/success`;

// Cloudburst - "Workflows"
export const WORKFLOW_ADD_NEW = `${BASE_CLOUDBURST}/new`;
export const WORKFLOW_DETAILS = `${BASE_CLOUDBURST}/:workflowId/details/:detail`;
export const WORKFLOW_SUBMISSIONS_LIST = `${BASE_CLOUDBURST}/:workflowId/submissions`;
export const GET_WORKFLOW_CANVAS = ({ workflowId }: { workflowId: number }) =>
  `${BASE_CLOUDBURST}/${workflowId}/details/canvas`;
export const GET_WORKFLOW_SETTINGS = ({ workflowId }: { workflowId: number }) =>
  `${BASE_CLOUDBURST}/${workflowId}/details/settings`;
export const GET_WORKFLOW_METRICS = ({ workflowId }: { workflowId: number }) =>
  `${BASE_CLOUDBURST}/${workflowId}/details/analytics`;
export const GET_WORKFLOW_SUBMISSIONS = ({ workflowId }: { workflowId: number }) =>
  `${BASE_CLOUDBURST}/${workflowId}/submissions`;

// URL Params
export const SORT = 'sort';
export const SORT_DIRECTION = 'sortDirection';
export const ASCENDING = 'asc';
export const DESCENDING = 'desc';
export const FILTER = 'filter';
export const FIELD_FILTER = 'fieldFilter';
export const CLASSIFICATION = 'classification';
export const FIELD_ID = 'fieldId';
export const TARGET_ID = 'targetId';
export const urlParams = {
  sort: SORT,
  sortDirection: SORT_DIRECTION,
  filters: FILTER,
  fieldFilters: FIELD_FILTER,
  classifications: CLASSIFICATION,
  fieldId: FIELD_ID,
  targetId: TARGET_ID,
};
